import * as React from "react"
import styled from 'styled-components';

import NavbarNotAnimated from '../components/NavbarNotAnimated';
import FooterWithPadding from '../components/FooterWithPadding';


const NewLayoutThree = () => {

return (
		<Grid>
			<NavbarNotAnimated />
			{/* <h1 className="about__title">Welcome To My Portfolio</h1> */}
			{/* <p className="FirstParagraph">
			<strong>Welcome to my Portfolio. </strong> If you're viewing this you probably have been raking through a ton of portfolios. 
				With that in mind, I designed my site to be skimable. 
				Each project is quickly summarizered with the essential information and then
				expanded upon as you keep reading. The idea here is that you should be able to skim
				quickly if needed or take a deeper look at your disgression with full detailed designs 
				and case studies/details being showcased if I meet with you 1 on 1.
			</p> */}
			<div className="text">
			<h1 className="about__title">About me</h1>
			<p>
				<strong>I'm a data-driven designer</strong> with a passion for 
				creating delightful to use digital experiences that resonate emotionally with end-users. 
				I have 6+ years of experience and have worked across many different environments.
			</p>
			<p>
			I knew I wanted to be a designer from a young age. I started coding websites & mobile games
			in my early teens. At 18, I attended a design/coding
			boot camp in Chicago. From there, I secured my first job and have been working 
			professionally since, mostly in the Chicago land area. My background is nontraditional, 
			but becoming more and more common in today's world. Because of this, I am a lifelong 
			learner and change does not scare me. 
			{/* learner and consider myself very adaptable. */}
			</p>
			<p>
			I've been fortunate to be surrounded by exceptionally talented coworkers and senior 
			design mentors that have taught me over the years. I approach each project I work 
			on from a fresh set of eyes. Taking in as much knowledge as I can to inform my 
			design choices. This ensures I can deliver at whatever scope and constraints I happen 
			to be working with. I enjoy discovering problems as much as I enjoy solving them. 
			I always assume that there is more to learn and more to question in any situation. 
			I love the nitty-gritty, nerdy parts of the digital design process and implementation. 
			{/* I've been fortunate to be surrounded by exceptionally talented coworkers and senior
			design mentors that have taught me over the years. I enjoy thoughtful conversations
			about problems and perfecting designs. I always assume that there is more to learn, 
			and more to be perfected in any situation. However, at the end of the day, I know 
			that what really matters is being able to deliver.  */}
			{/* a product that works */}
			</p>
			<p>
			<strong>My core skills</strong> lie at the intersection of design and technology.  
			I'm not a unicorn, but I prefer to code my own designs when applicable. I'm competent
			with UI/UX at any stage in  the design sprint and am always ready to throw together 
			a prototype. 
			Play and prototyping are a big part of my process both inside and outside of the workplace.
			It's what keeps my curiosity and child-like wonder with technology and design alive. 
			You'll often find me building, playing on, and gossiping about emerging tech and 
			brainstorming possible future use cases...
			</p>
			<p>
				<strong>As a team member</strong>, I am cross-disciplinary and get excited to work and learn from different
				departments. I am comfortable communicating across teams, especially engineering. Because 
				of my background with startups, I also believe no task is too big or small. I love helping
				out wherever I can and strive to be resourceful, flexible, and adaptable. 
			</p>
			<p>

			<strong>As a person</strong>, I am empathetic, eager, and positive. I am in the "zillennial" age range,
			an in-between generation. I grew up in an analog world that was quickly transitioning
			to digital. So, I am familiar with both but did not grow up siloed to one, I believe 
			it gives me a unique perspective.
			</p>
			<p>
				After years of working in many different environments (startups, agencies, 
				enterprises, SMBs, and freelance) I'm ready to take the next step in my career.
				Ideally, I'm seeking the opportunity to join a company for the long run,
				and really make a dent. 
			</p>
			<br />
			<br />
			<p className="skills"><strong>Core Proffesional Skills and Interest: </strong></p>
			<p className="skills">- Motion/Interaction Design</p>
			<p className="skills">- UX/UI</p>
			<p className="skills">- Design Systems</p>
			<p className="skills">- CSS</p>
			<p className="skills">- Healthcare</p>
			<p className="skills">- Web3/DeFi</p>
			<p className="skills">- Accessibility</p>
			<p className="skills">- Diversity & Inclusion</p>

			<h1 className="about__title">The fun stuff</h1>
			<p>I like to stay creative in my free time by making things with my hands and prioritizing exercise and staying mentally fit. 
				Building DIY furniture, learning new software, releasing side projects, making music, and cooking to name a few. I also follow the venture scene 
				closely and enjoy staying on top of the latest tech. 
				I'm a huge movie and music geek. When I'm not immersed in the latter I usually find myself hiking and exploring with
				my Labrador Ollie and spending time with my family.
			</p>
			</div>
			<FooterWithPadding />
		</Grid>
	)
}

export default NewLayoutThree


const Grid = styled.div`
	font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	max-width: 1110px;
	margin: 0 auto;
	padding-top: 32px;
	

	.about__title {
    font-weight: 800;
    color: var(--black-blue);
		margin-top: 6%;
    font-size: clamp(100%, 2rem + 7vw, 8rem);
		/* font-size: clamp( 8rem,2vw,2rem); */
		padding-bottom: 1.5%;
		max-width: 768px;
		/* margin: 0 auto; */
		/* padding-top: 58px; */
		@media (max-width: 650px) {
			padding-top: 0px;
		}
	}
	p {
		font-size: 2rem;
		line-height: 1.7;
		padding-bottom: 3%;
		color: var(--black-blue);
		max-width: 768px;
		/* margin: 0 auto; */
		@media (max-width: 650px) {
			font-size: 16px;
			padding-bottom: 4%;
		}
	}
	.FirstParagraph {
		padding-top: 4%;
	}
	.skills {
		padding-bottom: 2%;
	}

	/* .Header {
		padding-top: 4%;
		padding-bottom: 10%;
		h1 {
			font-size: clamp(100%, 48px + 2vw, 72px);
			padding-bottom: 1%;
			color: #222F3E;
		}
		p {
			font-size: 1.8rem;
			color: #222F3E;
			padding-bottom: 2.5%;
		} */
		
	.ButtonWrapper {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		grid-column-gap: 8px;
		@media (max-width: 1200px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		@media (max-width: 850px) {
			grid-template-columns: 1fr 1fr 1fr;
		}
		@media (max-width: 650px) {
			grid-template-columns: 1fr 1fr;
		}
	}
}


	@media (max-width: 1200px) {
		padding-left: 48px;
		padding-right: 48px;
	}
	@media (max-width: 850px) {
		padding-left: 48px;
		padding-right: 48px;
	}
	@media (max-width: 650px) {
		padding-left: 20px;
		padding-right: 20px;
	}
	
`


