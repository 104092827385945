import * as React from "react"
import styled from 'styled-components';
import { Link } from "gatsby"
import Resume from "../images/nate-davis-resume-2021.pdf"

const NavbarNotAnimated = (props) => {
  return (
    <Grid>

      <nav> 
        <StyledLinkHome to="/">🤳🏻👨🏼‍💻🛠🎨</StyledLinkHome>
        <div>
          <StyledLink to="/about">About Me</StyledLink>
          <a href={Resume} target="_blank">
            Resume
          </a>
        </div>
      </nav>


    </Grid>
  )
}

export default NavbarNotAnimated


const Grid = styled.div`

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0%;
    border-radius: 8px;
  }
  a {
    color: #222F3E;
  font-size: clamp( 14px, 2vw, 22px);
  margin-right: 16px;
  text-decoration: none;

  :hover {
    cursor: pointer;
  }
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  color: #222F3E;
  font-size: clamp( 14px, 2vw, 22px);
  margin-right: 16px;
  text-decoration: none;

  :hover {
    cursor: pointer;
  }
`;

const StyledLinkHome = styled(props => <Link {...props} />)`
  color: #222F3E;
  font-size: 24px;
  font-size: clamp( 18px, 2vw, 24px); 
  text-decoration: none;
	transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
	:active {
    transform: translateY(1px);
    transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
	:hover{
		opacity:0.8;
	}
`;


